<template>
  <gf-base>
    <div class="details">
      <el-dialog title="Delete Customer Group" :visible.sync="deleteModalVisible" append-to-body v-loading="deleteModalLoading" :close-on-click-modal="false">
        <gf-alert>
          <template #icon>
            <span class="svg-icon svg-icon-danger svg-icon-xxl">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <rect x="0" y="0" width="24" height="24"/>
                  <path d="M11.1669899,4.49941818 L2.82535718,19.5143571 C2.557144,19.9971408 2.7310878,20.6059441 3.21387153,20.8741573 C3.36242953,20.9566895 3.52957021,21 3.69951446,21 L21.2169432,21 C21.7692279,21 22.2169432,20.5522847 22.2169432,20 C22.2169432,19.8159952 22.1661743,19.6355579 22.070225,19.47855 L12.894429,4.4636111 C12.6064401,3.99235656 11.9909517,3.84379039 11.5196972,4.13177928 C11.3723594,4.22181902 11.2508468,4.34847583 11.1669899,4.49941818 Z" fill="#000000" opacity="0.3"/>
                  <rect fill="#000000" x="11" y="9" width="2" height="7" rx="1"/>
                  <rect fill="#000000" x="11" y="17" width="2" height="2" rx="1"/>
                </g>
              </svg>
            </span>
          </template>
          You are trying to delete this customer group. This action cannot be undone. Companies' members of this group will be automatically removed. Are you sure?
        </gf-alert>
        <template #footer>
          <gf-button type="secondary" class="mr-2" @click="deleteModalVisible = false">
            <template slot="icon">
              <span class="svg-icon svg-icon-sm">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                      <rect x="0" y="7" width="16" height="2" rx="1"/>
                      <rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"/>
                    </g>
                  </g>
                </svg>
              </span>
            </template>
            Close
          </gf-button>
          <gf-button type="danger" @click="remove">
            <template slot="icon">
              <span class="svg-icon svg-icon-white svg-icon-sm">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24"/>
                    <path d="M6,8 L18,8 L17.106535,19.6150447 C17.04642,20.3965405 16.3947578,21 15.6109533,21 L8.38904671,21 C7.60524225,21 6.95358004,20.3965405 6.89346498,19.6150447 L6,8 Z M8,10 L8.45438229,14.0894406 L15.5517885,14.0339036 L16,10 L8,10 Z" fill="#000000" fill-rule="nonzero"/>
                    <path d="M14,4.5 L14,3.5 C14,3.22385763 13.7761424,3 13.5,3 L10.5,3 C10.2238576,3 10,3.22385763 10,3.5 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3"/>
                  </g>
                </svg>
              </span>
            </template>
            Delete
          </gf-button>
        </template>
      </el-dialog>
      <gf-card :title="customerGroup.name" subtitle="Customer Group Information" v-loading="loading">
        <template #toolbar>
          <gf-button class="mr-2" type="primary" @click="edit">
            <template slot="icon">
              <span class="svg-icon svg-icon-white svg-icon-sx">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24"/>
                    <path d="M8,17.9148182 L8,5.96685884 C8,5.56391781 8.16211443,5.17792052 8.44982609,4.89581508 L10.965708,2.42895648 C11.5426798,1.86322723 12.4640974,1.85620921 13.0496196,2.41308426 L15.5337377,4.77566479 C15.8314604,5.0588212 16,5.45170806 16,5.86258077 L16,17.9148182 C16,18.7432453 15.3284271,19.4148182 14.5,19.4148182 L9.5,19.4148182 C8.67157288,19.4148182 8,18.7432453 8,17.9148182 Z" fill="#000000" fill-rule="nonzero" transform="translate(12.000000, 10.707409) rotate(-135.000000) translate(-12.000000, -10.707409) "/>
                    <rect fill="#000000" opacity="0.3" x="5" y="20" width="15" height="2" rx="1"/>
                  </g>
                </svg>
              </span>
            </template>
            Edit
          </gf-button>
          <gf-button class="mr-2" type="danger" @click="deleteModalVisible = true">
            <template slot="icon">
              <span class="svg-icon svg-icon-white svg-icon-sx">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24"/>
                    <path d="M6,8 L18,8 L17.106535,19.6150447 C17.04642,20.3965405 16.3947578,21 15.6109533,21 L8.38904671,21 C7.60524225,21 6.95358004,20.3965405 6.89346498,19.6150447 L6,8 Z M8,10 L8.45438229,14.0894406 L15.5517885,14.0339036 L16,10 L8,10 Z" fill="#000000" fill-rule="nonzero"/>
                    <path d="M14,4.5 L14,3.5 C14,3.22385763 13.7761424,3 13.5,3 L10.5,3 C10.2238576,3 10,3.22385763 10,3.5 L10,4.5 L5.5,4.5 C5.22385763,4.5 5,4.72385763 5,5 L5,5.5 C5,5.77614237 5.22385763,6 5.5,6 L18.5,6 C18.7761424,6 19,5.77614237 19,5.5 L19,5 C19,4.72385763 18.7761424,4.5 18.5,4.5 L14,4.5 Z" fill="#000000" opacity="0.3"/>
                  </g>
                </svg>
              </span>
            </template>
            Delete
          </gf-button>
          <gf-button type="secondary" @click="back">
            <template slot="icon">
              <span class="svg-icon svg-icon-sx">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <polygon points="0 0 24 0 24 24 0 24"/>
                    <path d="M4.7071045,12.7071045 C4.3165802,13.0976288 3.68341522,13.0976288 3.29289093,12.7071045 C2.90236664,12.3165802 2.90236664,11.6834152 3.29289093,11.2928909 L9.29289093,5.29289093 C9.67146987,4.914312 10.2810563,4.90106637 10.6757223,5.26284357 L16.6757223,10.7628436 C17.0828413,11.136036 17.1103443,11.7686034 16.7371519,12.1757223 C16.3639594,12.5828413 15.7313921,12.6103443 15.3242731,12.2371519 L10.0300735,7.38413553 L4.7071045,12.7071045 Z" fill="#000000" fill-rule="nonzero" transform="translate(10.000001, 8.999997) scale(-1, -1) rotate(90.000000) translate(-10.000001, -8.999997) "/>
                    <path d="M20,8 C20.5522847,8 21,8.44771525 21,9 C21,9.55228475 20.5522847,10 20,10 L13.5,10 C12.9477153,10 12.5,10.4477153 12.5,11 L12.5,21.0415946 C12.5,21.5938793 12.0522847,22.0415946 11.5,22.0415946 C10.9477153,22.0415946 10.5,21.5938793 10.5,21.0415946 L10.5,11 C10.5,9.34314575 11.8431458,8 13.5,8 L20,8 Z" fill="#000000" fill-rule="nonzero" opacity="0.3" transform="translate(15.750000, 15.020797) scale(-1, 1) translate(-15.750000, -15.020797) "/>
                  </g>
                </svg>
              </span>
            </template>
            Back
          </gf-button>
        </template>
        <div class="header-details">
          <div class="image-container"><i class="el-icon-suitcase"></i></div>
          <div class="headers-extra-details">
            <div class="column">
              <div class="row">
                <div class="left">Customers in Group</div>
                <div class="right">
                  <span v-if="companies.length > 0">{{ companies.length }}</span>
                  <span v-else>None assigned</span>
                </div>
              </div>
              <div class="row">
                <div class="left">Default Price List</div>
                <div class="right">{{ customerGroup.price_list.name }}</div>
              </div>
              <div class="row">
                <div class="left">Default Discount Rate</div>
                <div class="right">{{ customerGroup.discount_rate }}%</div>
              </div>
              <div class="row">
                <div class="left">Default Payment Term</div>
                <div class="right">{{ customerGroup.payment_term.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </gf-card>
    </div>
    <div class="list" v-loading="loadingList">
      <gf-card title="Companies" subtitle="List of Customer Group Members">
        <el-dialog title="Bulk Assign to Customer Group" :visible.sync="assignModalVisible" append-to-body v-loading="assignModalLoading" :close-on-click-modal="false">
          <gf-form>
            <template #alert>
              <gf-alert>
                <template #icon>
                  <span class="svg-icon svg-icon-primary svg-icon-md">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect x="0" y="0" width="24" height="24"/>
                        <path d="M6,7 C7.1045695,7 8,6.1045695 8,5 C8,3.8954305 7.1045695,3 6,3 C4.8954305,3 4,3.8954305 4,5 C4,6.1045695 4.8954305,7 6,7 Z M6,9 C3.790861,9 2,7.209139 2,5 C2,2.790861 3.790861,1 6,1 C8.209139,1 10,2.790861 10,5 C10,7.209139 8.209139,9 6,9 Z" fill="#000000" fill-rule="nonzero"/>
                        <path d="M7,11.4648712 L7,17 C7,18.1045695 7.8954305,19 9,19 L15,19 L15,21 L9,21 C6.790861,21 5,19.209139 5,17 L5,8 L5,7 L7,7 L7,8 C7,9.1045695 7.8954305,10 9,10 L15,10 L15,12 L9,12 C8.27142571,12 7.58834673,11.8052114 7,11.4648712 Z" fill="#000000" fill-rule="nonzero" opacity="0.3"/>
                        <path d="M18,22 C19.1045695,22 20,21.1045695 20,20 C20,18.8954305 19.1045695,18 18,18 C16.8954305,18 16,18.8954305 16,20 C16,21.1045695 16.8954305,22 18,22 Z M18,24 C15.790861,24 14,22.209139 14,20 C14,17.790861 15.790861,16 18,16 C20.209139,16 22,17.790861 22,20 C22,22.209139 20.209139,24 18,24 Z" fill="#000000" fill-rule="nonzero"/>
                        <path d="M18,13 C19.1045695,13 20,12.1045695 20,11 C20,9.8954305 19.1045695,9 18,9 C16.8954305,9 16,9.8954305 16,11 C16,12.1045695 16.8954305,13 18,13 Z M18,15 C15.790861,15 14,13.209139 14,11 C14,8.790861 15.790861,7 18,7 C20.209139,7 22,8.790861 22,11 C22,13.209139 20.209139,15 18,15 Z" fill="#000000" fill-rule="nonzero"/>
                      </g>
                    </svg>
                  </span>
                </template>
                Select Customer Group to assign selected companies
              </gf-alert>
            </template>
            <el-form size="small" label-width="170px">
              <el-form-item label="Customer Group">
                <el-autocomplete style="width: 100%" v-model="searchCg" :fetch-suggestions="getCustomerGroups" @select="selectCustomerGroup"></el-autocomplete>
              </el-form-item>
              <el-form-item label="Default Price List">
                <el-input :value="selectedCustomerGroup.price_list.name" disabled></el-input>
              </el-form-item>
              <el-form-item label="Default Discount">
                <el-input v-model="selectedCustomerGroup.discount_rate" disabled>
                  <template slot="append"><strong>%</strong></template>
                </el-input>
              </el-form-item>
              <el-form-item label="Default Payment Term">
                <el-input :value="selectedCustomerGroup.payment_term.name" disabled></el-input>
              </el-form-item>
            </el-form>
            <template #footer>
              <gf-button type="secondary" @click="assignModalVisible = false">
                <template slot="icon">
                  <span class="svg-icon svg-icon-sm">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                          <rect x="0" y="7" width="16" height="2" rx="1"/>
                          <rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"/>
                        </g>
                      </g>
                    </svg>
                  </span>
                </template>
                Close
              </gf-button>
              <gf-button type="primary" @click="assignCompanies">
                <template slot="icon">
                  <span class="svg-icon svg-icon-white svg-icon-sm">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <rect fill="#000000" x="4" y="11" width="16" height="2" rx="1"/>
                        <rect fill="#000000" opacity="0.3" transform="translate(12.000000, 12.000000) rotate(-270.000000) translate(-12.000000, -12.000000) " x="4" y="11" width="16" height="2" rx="1"/>
                      </g>
                    </svg>
                  </span>
                </template>
                Assign
              </gf-button>
            </template>
          </gf-form>
        </el-dialog>
        <el-dialog title="Bulk Unassign from Customer Group" :visible.sync="unassignModalVisible" append-to-body v-loading="unassignModalLoading" :close-on-click-modal="false">
          <gf-alert>
            <template #icon>
              <span class="svg-icon svg-icon-warning svg-icon-xxl">
                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                  <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                    <rect x="0" y="0" width="24" height="24"/>
                    <circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10"/>
                    <rect fill="#000000" x="11" y="7" width="2" height="8" rx="1"/>
                    <rect fill="#000000" x="11" y="16" width="2" height="2" rx="1"/>
                  </g>
                </svg>
              </span>
            </template>
            You are trying to remove {{ this.selected.length }} companies from their customer group. Are you sure?
          </gf-alert>
          <template #footer>
            <gf-button type="secondary" class="mr-2" @click="unassignModalVisible = false">
              <template slot="icon">
                <span class="svg-icon svg-icon-sm">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                        <rect x="0" y="7" width="16" height="2" rx="1"/>
                        <rect opacity="0.3" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000) " x="0" y="7" width="16" height="2" rx="1"/>
                      </g>
                    </g>
                  </svg>
                </span>
              </template>
              Close
            </gf-button>
            <gf-button type="warning" @click="unassignCompanies">
              <template slot="icon">
                <span class="svg-icon svg-icon-white svg-icon-sm">
                  <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <rect fill="#000000" x="4" y="11" width="16" height="2" rx="1"/>
                    </g>
                  </svg>
                </span>
              </template>
              Unassign
            </gf-button>
          </template>
        </el-dialog>
        <gf-table :data="companies" :selection-command="selectionCommand" @command="command" @row-click="readCompany" v-loading="loadingTable">
          <el-table-column type="selection">
          </el-table-column>
          <el-table-column label="Name" prop="name" min-width="200">
            <template #default="slot">
              {{ $StringFormat(slot.row.name, 20) }}
            </template>
          </el-table-column>
          <el-table-column label="Company code" prop="code" min-width="130"></el-table-column>
          <el-table-column label="Email" prop="email" min-width="150">
            <template #default="slot">
              {{ $StringFormat(slot.row.email, 20) }}
            </template>
          </el-table-column>
          <el-table-column label="Phone" prop="phone_number" min-width="150"></el-table-column>
          <el-table-column label="Created" prop="created_at" min-width="150">
            <template #default="slot">
              {{ $DateFormat(slot.row.created_at) }}
            </template>
          </el-table-column>
          <el-table-column label="Last Updated" prop="updated_at" min-width="150">
            <template #default="slot">
              {{ $DateFormat(slot.row.updated_at) }}
            </template>
          </el-table-column>
        </gf-table>
        <template #footer>
          <gf-pagination :pagination="pagination" @change="getCompanies"></gf-pagination>
        </template>
      </gf-card>
    </div>
  </gf-base>
</template>

<style lang="scss" scoped>
.details {
  margin-bottom: 25px;
}

.list {
  flex-grow: 1;
}

.header-details {
  display: flex;
  align-items: center;

  .image-container {
    display: flex;
    font-size: 110px;
    flex-basis: 170px;
    justify-content: center;
    align-items: center;
  }

  .headers-extra-details {
    font-weight: 400;
    font-size: 13px;
    display: flex;
    flex-grow: 1;
    justify-content: flex-start;

    .column {
      display: flex;
      min-width: 300px;
      flex-direction: column;

      .row {
        display: flex;
        line-height: 17.5px;

        .left {
          width: 50%;
          text-align: right;
          border-right: 1px solid #dadada;
          margin-right: 10px;
          padding: 5px 10px 5px 0;
        }

        .right {
          padding: 5px 10px 5px 0;
          text-align: left;
        }
      }
    }
  }
}
</style>

<script>
// Services
import CustomerGroupService from '@/services/v1/CustomerGroup'
import CustomerGroupCompanyService from '@/services/v1/CustomerGroupCompany'

export default {
  data () {
    return {
      // ui
      loading: false,
      loadingList: false,
      loadingTable: false,

      selectionCommand: [
        { label: 'Assign to another Customer Group', command: 'assign' },
        { label: 'Unassign from Customer Group', command: 'unassign' }
      ],
      selected: [],

      //
      searchCg: '',

      // modal
      assignModalVisible: false,
      assignModalLoading: false,
      unassignModalVisible: false,
      unassignModalLoading: false,
      deleteModalVisible: false,
      deleteModalLoading: false,

      pagination: {
        total: 0,
        page: {
          number: 1,
          size: 25
        }
      },

      // main
      customerGroup: {
        name: '',
        minimum_order_value: '',
        discount_rate: '',
        price_list: {
          name: ''
        },
        payment_term: {
          name: ''
        }
      },
      selectedCustomerGroup: {
        id: null,
        name: null,
        price_list: {
          name: null
        },
        payment_term: {
          name: null
        },
        discount_rate: null
      },
      companies: [],

      // route params
      customerGroupId: this.$route.params.id
    }
  },
  methods: {
    // navigation
    edit () {
      this.$router.push({ name: 'customer-group-edit', params: { id: this.customerGroupId } })
    },
    back () {
      this.$router.push({ name: 'customer-group-list' })
    },

    readCompany (row) {
      this.$router.push({ name: 'company-view', params: { id: row.id } })
    },
    async remove () {
      try {
        this.deleteModalLoading = true
        const cgService = new CustomerGroupService(this.customerGroupId)
        await cgService.del()

        this.deleteModalVisible = false
        this.$message.success(`Successfully deleted ${this.customerGroup.name}`)
        this.$router.push({ name: 'customer-group-list' })
      } catch (error) {
        this.$Error(error)
      } finally {
        this.deleteModalLoading = false
      }
    },
    async assignCompanies () {
      try {
        if (!this.selectedCustomerGroup.id) {
          this.$notify.info({
            title: 'Assign Customer Group',
            message: 'No selected Customer Group.'
          })
          return
        }

        this.assignModalLoading = true
        for (let i = 0; i < this.selected.length; i++) {
          const sl = this.selected[i]

          for (const key in sl) {
            if (key === 'id' || key === 'setting') continue
            delete sl[key]
          }
        }

        const cgService = new CustomerGroupService(this.selectedCustomerGroup.id)
        await cgService.assign(this.selected)
        this.$message.success(`Successfully added ${this.selected.length} companies to ${this.selectedCustomerGroup.name}`)

        // clear
        this.searchCg = ''
        this.selectedCustomerGroup = {
          id: null,
          name: null,
          price_list: {
            name: null
          },
          payment_term: {
            name: null
          },
          discount_rate: null
        }

        this.assignModalVisible = false
        this.getCompanies()
      } catch (error) {
        this.$Error(error)
      } finally {
        this.assignModalLoading = false
      }
    },
    async unassignCompanies () {
      try {
        this.unassignModalLoading = true
        for (let i = 0; i < this.selected.length; i++) {
          const sl = this.selected[i]

          for (const key in sl) {
            if (key === 'id' || key === 'setting') continue
            delete sl[key]
          }
        }

        const cgService = new CustomerGroupService()
        await cgService.unassign(this.selected)
        this.$message.success(`Successfully removed ${this.selected.length} companies from their Customer Group`)
        this.getCompanies()

        this.unassignModalVisible = false
      } catch (error) {
        this.$Error(error)
      } finally {
        this.unassignModalLoading = false
      }
    },
    command (event) {
      this.selected = event.selection
      if (event.cmd === 'assign') {
        this.assignModalVisible = true
        return
      }

      if (event.cmd === 'unassign') {
        this.unassignModalVisible = true
      }
    },

    // autocomplete
    selectCustomerGroup (customerGroup) {
      this.selectedCustomerGroup = customerGroup.detail
    },

    // Fetch services
    async getCustomerGroup () {
      try {
        this.loading = true
        this.loadingList = true
        const cgService = new CustomerGroupService(this.customerGroupId)
        const result = await cgService.get()

        this.customerGroup.name = result.data.name
        this.customerGroup.minimum_order_value = result.data.minimum_order_value
        this.customerGroup.discount_rate = result.data.discount_rate
        this.customerGroup.price_list.name = result.data.price_list ? result.data.price_list.name : '-'
        this.customerGroup.payment_term.name = result.data.payment_term ? result.data.payment_term.name : '-'
      } catch (error) {
        this.$Error(error)
        this.$route.back()
      } finally {
        this.loading = false
        this.loadingList = false
      }
    },
    async getCompanies () {
      try {
        this.loadingTable = true
        const cgcService = new CustomerGroupCompanyService(this.customerGroupId)
        const result = await cgcService.list(this.pagination.page)
        this.companies = result.data.rows

        if (result.data.count === 0 && result.data.rows.length !== 0) {
        } else this.pagination.total = result.data.count
      } catch (error) {
        this.$Error(error)
        this.$route.back()
      } finally {
        this.loadingTable = false
      }
    },
    async getCustomerGroups (query, cb) {
      try {
        const out = []
        const filter = {}

        if (query === '' || !query) {
          delete filter.q
        } else {
          filter.q = {
            like: query
          }
        }

        const cgService = new CustomerGroupService()
        const result = await cgService.list(null, null, filter)

        for (let i = 0; i < result.data.rows.length; i++) {
          const cg = result.data.rows[i]
          out.push({
            value: cg.name,
            detail: cg
          })
        }

        cb(out)
      } catch (error) {
        this.$Error(error)
      }
    }
  },
  async mounted () {
    this.loading = true
    this.loadingList = true
    this.loadingTable = true

    await this.getCustomerGroup()
    await this.getCompanies()

    this.$store.dispatch(this.$SET_BREADCRUMB, [
      { title: 'Relationship', route: '' },
      { title: 'Customer Groups' },
      { title: this.customerGroup.name }
    ])
  }
}
</script>
